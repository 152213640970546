exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-adr-js": () => import("./../../../src/pages/adr.js" /* webpackChunkName: "component---src-pages-adr-js" */),
  "component---src-pages-components-dialog-js": () => import("./../../../src/pages/components/dialog.js" /* webpackChunkName: "component---src-pages-components-dialog-js" */),
  "component---src-pages-components-menu-js": () => import("./../../../src/pages/components/menu.js" /* webpackChunkName: "component---src-pages-components-menu-js" */),
  "component---src-pages-dl-subscription-js": () => import("./../../../src/pages/dl/subscription.js" /* webpackChunkName: "component---src-pages-dl-subscription-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-stage-js": () => import("./../../../src/pages/stage.js" /* webpackChunkName: "component---src-pages-stage-js" */),
  "component---src-pages-t-c-js": () => import("./../../../src/pages/t&c.js" /* webpackChunkName: "component---src-pages-t-c-js" */)
}

